









































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { generalDataService } from '@/services/GeneralDataService';
import EventBus from '@/EventBus';
import DataTable from '@/components/common/DataTable.vue';
import ErrorBar from '@/components/common/ErrorBar.vue';
import LoadingBar from '@/components/common/LoadingBar.vue';
import Multiselect from 'vue-multiselect';

@Component({
  components: { LoadingBar, ErrorBar, Header, DataTable, Multiselect },
  props: ['title', 'newItem', 'modelFields', 'listOptions', 'apiPath', 'urlPath', 'deepPath', 'deepPathName'],
})
export default class GeneralCrudList extends Vue {
  data: any = [];
  total = 0;
  dataModel: any = {};
  type: any = '';
  isListError = false;
  isLoadingList = false;
  isItemError = false;
  isLoadingItem = false;
  isSavingItem = false;

  @Watch('modelFields', { immediate: true, deep: true })
  updateDataModel() {
    this.emptyModel();
  }

  @Watch('type')
  setForm() {
    if (!!this.type && this.type !== 'new') {
      this.isLoadingItem = true;
      this.isItemError = false;
      generalDataService
        .getItem(this.type, this.$props.apiPath)
        .then((data: any) => {
          const dataToSet: any = {};
          this.$props.modelFields.map((field: any) => {
            if (field.type === 'multiselect') {
              dataToSet[field.name] = data[field.name]
                .split(',')
                .map((item: any) => field.options.find((opt: any) => parseInt(opt.id, 10) === parseInt(item, 10)));
            } else {
              dataToSet[field.name] = data[field.name];
            }
          });
          this.dataModel = dataToSet;
          this.isLoadingItem = false;
        })
        .catch(() => {
          this.isItemError = true;
          this.isLoadingItem = false;
        });
    }
  }

  emptyModel() {
    if (this.$props.modelFields && this.$props.modelFields.length) {
      this.dataModel = {};
      this.$props.modelFields.map((field: any) => {
        this.dataModel[field.name] = '';
      });
      this.dataModel = { ...this.dataModel };
    }
  }

  mounted() {
    this.type = this.$route.params.type || '';
    this.getList();
  }

  getList() {
    this.isListError = false;
    this.isLoadingList = true;
    generalDataService
      .list(this.$props.apiPath)
      .then(data => {
        this.data = data.items;
        this.total = data.total;
        this.isLoadingList = false;
      })
      .catch(() => {
        this.data = [];
        this.total = 0;
        this.isListError = true;
        this.isLoadingList = false;
      });
  }

  saveItem() {
    this.isSavingItem = true;
    const dataToSave: any = {};
    this.$props.modelFields.map((field: any) => {
      if (field.type === 'multiselect') {
        dataToSave[field.name] = this.dataModel[field.name].map((item: any) => item.id).join(',');
      } else {
        dataToSave[field.name] = this.dataModel[field.name];
      }
    });
    if (!!this.type && this.type !== 'new') {
      generalDataService
        .update(this.type, this.$props.apiPath, dataToSave)
        .then(() => {
          this.isSavingItem = false;
          EventBus.$emit('show-success', 'Zapisano');
          this.emptyModel();
          this.goTo('');
          this.getList();
        })
        .catch(() => {
          this.isSavingItem = false;
        });
    } else {
      generalDataService
        .add(this.$props.apiPath, dataToSave)
        .then(() => {
          this.isSavingItem = false;
          EventBus.$emit('show-success', 'Zapisano');
          this.emptyModel();
          this.getList();
        })
        .catch(() => {
          this.isSavingItem = false;
        });
    }
  }

  goTo(path: string) {
    this.type = path;
    if (path === '') {
      this.getList();
    }
    if (path === 'new') {
      this.emptyModel();
    }
    this.$router.push(`/${this.$props.urlPath}/${path}`).catch(() => {});
  }
}
