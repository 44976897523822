






















import { Component, Vue } from 'vue-property-decorator';
import { tokenService } from '@/services/TokenService';

@Component({})
export default class Header extends Vue {
  logout() {
    tokenService.removeToken();
    this.$router.push('/').catch(() => {});
  }
  goTo(path: string) {
    this.$router.push(path).catch(() => {});
  }
}
